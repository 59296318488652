import styled from 'styled-components';

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; // Aligns content to the leading edge
  padding: 20px;
  width: 100%;
  max-width: 768px; // Adjust as needed for your design
`;

export const PaddleImage = styled.img`
  width: 100%;
  max-width: 300px; // Adjust size as needed
  border-radius: 10px;
  margin: 20px 0;
`;

export const PaddleDetails = styled.div`
  width: 100%;
  text-align: left; // Aligns text to the leading side
`;

export const PaddleBrand = styled.h1`
  color: blue;
  font-size: 1.8em;
  text-align: left; // Aligns text to the leading side
`;

export const PaddleName = styled.h2`
  color: grey;
  font-size: 1.5em;
  text-align: left; // Aligns text to the leading side
`;

export const ExpandableContainer = styled.div`
  background: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

export const DetailItem = styled.div`
  margin: 5px 0;
  text-align: left; // Ensures content aligns to the leading side
`;

export const DetailLabel = styled.span`
  font-weight: bold;
`;

export const ExpandToggle = styled.button`
  background-color: transparent;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
`;

export const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; // Aligns content to the leading side
`;

export const UserName = styled.h2`
  font-size: 1.2em;
`;

export const UserEmail = styled.h3`
  font-size: 1em;
`;

export const ProfileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 20px;
`;

export const LeftProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;  // Takes up the rest of the space after the image
`;

export const StatContainer = styled.div`
  display: flex;
  justify-content: flex-start; // Aligns content to the leading edge
  width: 100%; // Takes full width
  gap: 10px; // Adds space between items if needed
`;

export const StatBlock = styled.div`
  display: flex;
  flex-direction: column; // Aligns items vertically
`;

export const StatLabel = styled.div`
  color: blue; // Sets text color for label
  font-weight: bold; // Makes label bold
`;

export const StatValue = styled.div`
  color: grey; // Sets text color for value
`;


// import styled from 'styled-components';

// export const DetailContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   width: 100%;
//   max-width: 768px; // Adjust as needed for your design
// `;

// export const PaddleImage = styled.img`
//   width: 100%;
//   max-width: 300px; // Adjust size as needed
//   border-radius: 10px;
//   margin: 20px 0;
// `;

// export const PaddleDetails = styled.div`
//   width: 100%;
//   text-align: center;
// `;

// export const PaddleBrand = styled.h1`
//   color: blue;
//   font-size: 1.8em;
// `;

// export const PaddleName = styled.h2`
//   color: grey;
//   font-size: 1.5em;
// `;

// export const ExpandableContainer = styled.div`
//   background: #f0f0f0;
//   border: 1px solid #ddd;
//   padding: 10px;
//   margin: 10px 0;
//   display: ${props => props.isOpen ? 'block' : 'none'};
// `;

// export const DetailItem = styled.div`
//   margin: 5px 0;
// `;

// export const DetailLabel = styled.span`
//   font-weight: bold;
// `;

// export const ExpandToggle = styled.button`
//   background-color: transparent;
//   border: none;
//   color: blue;
//   cursor: pointer;
//   padding: 5px;
//   font-size: 16px;
// `;

// export const ProfileImage = styled.img`
//   width: 120px;
//   height: 120px;
//   border-radius: 50%;
//   object-fit: cover;
// `;

// export const UserInfo = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// export const UserName = styled.h2`
//   font-size: 1.2em;
// `;

// export const ProfileDetails = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   padding: 20px;
// `;

// export const LeftProfileDetails = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   flex: 1;  // Takes up the rest of the space after the image
// `;

// // export const StatContainer = styled.div`
// //   display: flex;
// //   justify-content: ; // Distributes space evenly
// //   width: 100%; // Ensures it takes full container width
// // `;

// export const StatContainer = styled.div`
//   display: flex;
//   justify-content: flex-start; // Aligns content to the left (leading edge)
//   width: 100%; // Takes full width
//   gap: 10px; // Adds space between items if needed
// `;

// export const StatBlock = styled.div`
//   display: flex;
//   flex-direction: column; // Aligns items vertically
// `;

// export const StatLabel = styled.div`
//   color: blue; // Sets text color for label
//   font-weight: bold; // Makes label bold
// `;

// export const StatValue = styled.div`
//   color: grey; // Sets text color for value
// `;




// import styled from 'styled-components';
// export const DetailContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   margin: auto;
//   width: 100%; // Use full width for mobile responsiveness
//   @media (min-width: 768px) {
//     flex-direction: row;
//     justify-content: space-around; // Adjust based on your layout preference
//   }
// `;

// export const PaddleImage = styled.img`
//   width: 80%; // Adjust based on your need
//   max-width: 300px; // Prevent the image from being too large on bigger screens
//   height: auto;
//   border-radius: 5px;
//   margin-top: 20px; // Add some space above the image
// `;

// export const PaddleDetails = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   @media (min-width: 768px) {
//     align-items: flex-start;
//     margin-left: 20px;
//   }
// `;

// export const PaddleBrand = styled.h1`
//   font-size: 1.8em; // Larger size
//   color: blue; // Brand color
//   margin: 0.5em 0;
// `;

// export const PaddleName = styled.h2`
//   font-size: 1.4em;
//   color: grey; // Name color
//   margin: 0.25em 0;
// `;

// export const DetailItem = styled.div`
//   margin: 0.25em 0;
// `;

// export const DetailLabel = styled.span`
//   font-weight: bold;
// `;

// export const ProfileDetails = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 20px;
//   width: 100%; // Full width for alignment
// `;

// export const ProfileImage = styled.img`
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   object-fit: cover;
//   margin-top: 10px; // Space from the top element
// `;

// export const UserName = styled.h2`
//   font-size: 1.2em;
//   margin-top: 10px;
// `;

// export const UserInfo = styled.p`
//   font-size: 1em;
//   color: #666;
//   margin: 5px 0;
// `;