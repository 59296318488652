import React, { useState }  from 'react';
import InfoSection          from './../components/InfoSection';
import ComingSoon           from './comingSoon';
import NotifyMe             from './../components/NotifyMe';
import backgroundImage      from './../assets/images/paddle-portal-BG.jpg';

import {
  adminHomeObj1,
  adminHomeObj2,
  adminHomeObj3,
  adminHomeObj4,
  adminHomeObj5 }           from '../components/InfoSection/Data';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ 
      backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover'}}>
      <ComingSoon />
      <NotifyMe />
      <InfoSection {...adminHomeObj1} />
      <InfoSection {...adminHomeObj2} />
      <InfoSection {...adminHomeObj3} />
      <InfoSection {...adminHomeObj4} />
      <InfoSection {...adminHomeObj5} />
    </div>
  );
}

export default Home;
